<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span> <i class="far fa-image"></i>&nbsp;Logo</span>
    </div>
    <el-form v-loading="loading" :model="company" label-position="top">
      <el-row :gutter="10">
        <el-col :sm="16">
          <el-form-item>
            <span v-show="uploading">
              <i class="far fa-spinner fa-pulse fa-fw"></i>
            </span>
            <el-upload
              class="logo-uploader"
              :action="urlUpload"
              :data="{ id: company.id, companyKey: company.companyKey }"
              :show-file-list="false"
              :on-error="fileOnError"
              :on-success="fileOnSuccess"
              :before-upload="fileBeforeUpload"
              :headers="{ Authorization: 'Bearer ' + token }"
            >
              <img v-if="imageUrl" :src="imageUrl" class="logo-display" />
              <i v-else class="el-icon-plus logo-uploader-icon"></i>
            </el-upload>
            <span class="help"
              >Tamaño de imagen preferido: 320px x 160px @ 72 DPI Tamaño máximo
              de 1MB y en formato permitidos png.</span
            >
          </el-form-item>
          <el-form-item>
            <el-button
              v-show="hasLogo"
              size="mini"
              type="danger"
              @click.prevent="removeimage"
            >
              Eliminar
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'CompanyLog',
  data() {
    return {
      company: {},
      token: localStorage.getItem('token'),
      urlUpload: '',
      imageUrl: '',
      hasLogo: false,
      loading: false,
      uploading: false,
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      const _this = this
      _this.loading = true
      _this.urlUpload = `${Vue.http.options.root}companies/${_this.$route.params.id}/logo/upload`
      _this.$http.get(`companies/${_this.$route.params.id}`).then(
        response => {
          _this.loading = false
          _this.company = response.body
          if (response.body.companyLogo) {
            _this.imageUrl = `${
              response.body.companyLogo
            }?last=${new Date().getTime()}`
            _this.hasLogo = true
          }
        },
        response => {
          _this.loading = false
        },
      )
    },
    removeimage() {
      const _this = this
      _this.uploading = true
      _this.$http
        .delete(
          `companies/${_this.company.id}/logo/remove?companyKey=${_this.company.companyKey}`,
        )
        .then(
          response => {
            _this.company.companyLogo = ''
            _this.hasLogo = false
            _this.imageUrl = ''
            _this.uploading = false
          },
          response => {
            _this.uploading = false
          },
        )
    },
    fileOnSuccess(res, file) {
      const _this = this

      // console.log('fileOnSuccess', file)
      // console.log('res', res)
      if (file.status === 'success' && res.url) {
        // console.log('TCL: fileOnChange -> file.response.url', res.url)
        _this.company.companyLogo = res.url
        _this.hasLogo = true
        _this.imageUrl = `${res.url}?last=${new Date().getTime()}`
      }
      this.uploading = false
    },
    fileBeforeUpload(file) {
      this.uploading = true
      const isValidFormat =
        file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 <= 1

      if (!isValidFormat) {
        this.$message.error('La imagen debe estar en formato JPG o PNG!')
      }
      if (!isLt2M) {
        this.$message.error('La imagen excede los 2MB!')
      }

      return isValidFormat && isLt2M
    },
    fileOnError(err, file) {
      console.log('error', err)
    },
  },
}
</script>
<style>
.logo-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.logo-uploader .el-upload:hover {
  border-color: #409eff;
}
.logo-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 240px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  display: block;
  margin-bottom: 8px;
}
.logo-display {
  display: block;
  max-width: 240px;
  max-height: 120px;
  margin-bottom: 8px;
}
</style>
